<template>
  <div class="newsletter_wrap">
    <div class="newsletter">
      <div class="newsletter_inner">
        <h2 class="newsletter_title">
          {{ $t('newsletter.title') }}
        </h2>
        <p class="newsletter_desct">
          {{ $t('newsletter.description') }}
        </p>
        <form class="newsletter_form" @submit.prevent="subscribe()">
          <div class="input_group">
            <input v-model="form.email" type="email" class="custom_input" placeholder="Email">
            <span class="floating_label">
              {{ $t('newsletter.email') }}
            </span>
            <i class="email_icon" />
          </div>
          <button type="submit" class="submit_btn" aria-label="Subscribe">
            <i class="letter_icon" />
          </button>
        </form>
      </div>
      <div class="bg_image" />
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  data () {
    return {
      subscribe_class: '',
      subscribe_accepted: false,
      form: {
        type: 'subscribe',
        email: null
      }
    }
  },
  computed: {
    ...mapGetters('validation', ['errors'])
  },
  destroyed () {
    this.clearErrors()
  },
  methods: {
    ...mapActions('validation', ['setErrors', 'clearErrors']),
    subscribe () {
      if (this.form.email && this.form.email.length) {
        this.$axios.post('/api/promo/messages/', {
          email: this.form.email,
          type: this.form.type
        })
          .then(() => {
            this.subscribe_class = 'sent'
            this.subscribe_accepted = true
            this.$fb.track('Lead')
          })
          .catch((error) => {
            this.subscribe_class = ''
            this.subscribe_accepted = false
            this.setErrors(error.response.data.errors)
          })
      } else {
        this.setErrors([{ email: 'fail' }])
      }
    }
  }
}
</script>
