import 'swiper/swiper.scss'
import 'swiper/modules/pagination/pagination.scss'

export default (context, inject) => {
  const formatSeoTags = (i18nHead, index, canonical) => {
    i18nHead.link.forEach((item, i) => {
      const url = item.href.split('?')
      item.href = url[0] + (url[0].slice(-1) !== '/' ? '/' : '') + (url[1] ? ('?' + url[1]) : '')
      if (item.rel === 'canonical') {
        if (canonical && index === 'enabled') {
          i18nHead.link[i] = { hid: 'i18n-can', rel: 'canonical', href: canonical }
        } else {
          i18nHead.link[i] = { hid: 'i18n-can', rel: 'empty-can' }
        }
      }
      if (item.rel === 'alternate') {
        if (index !== 'enabled') {
          i18nHead.link[i] = { hid: 'i18n-alternate', rel: 'empty-alt' }
        }
      }
    })
    if (index === 'enabled') {
      i18nHead.meta.push({ name: 'robots', content: 'index,follow' })
    } else {
      i18nHead.meta.push({ name: 'robots', content: 'noindex,nofollow' })
    }
    return i18nHead
  }
  inject('formatSeoTags', formatSeoTags)

  const formatUrl = (url) => {
    const splitted = url.split('?')
    return splitted[0] + (splitted[0].slice(-1) !== '/' ? '/' : '') + (splitted[1] ? ('?' + splitted[1]) : '')
  }
  inject('formatUrl', formatUrl)

  const moneyFormat = (number) => {
    return new Intl.NumberFormat('uk-UA', {
      style: 'currency',
      currency: 'UAH',
      currencyDisplay: 'symbol',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }).format(number).replace('грн', '₴')
  }
  inject('moneyFormat', moneyFormat)

  const imgMeta = (type, name, count = 0) => {
    return name + (count > 0 ? (' ' + count) : '') + (type === 'title' ? (' ' + process.env.TITLE) : '')
  }
  inject('imgMeta', imgMeta)

  const parsedFromUrl = (params) => {
    const urlParams = []
    urlParams.attributes = []
    urlParams.sliders = []
    if (params && params.includes('filter-')) {
      const paths = params.replace('filter-', '').split('-and-')
      if (paths.length) {
        paths.forEach((path) => {
          if (path && path !== '' && path.includes('-is-')) {
            const pathName = path.split('-is-')[0]
            const pathValue = path.split('-is-')[1]
            if (pathName === 'price' && pathValue.includes('-to-')) {
              urlParams.price = pathValue
            } else if (pathName !== 'price' && pathValue.includes('-to-')) {
              urlParams.sliders.push(path)
            } else {
              urlParams.attributes.push(path)
            }
          }
        })
      }
    }
    if (urlParams.attributes && !Array.isArray(urlParams.attributes)) {
      urlParams.attributes = urlParams.attributes.split(' ')
    }
    if (!urlParams.attributes.length) {
      delete urlParams.attributes
    }
    if (urlParams.sliders && !Array.isArray(urlParams.sliders)) {
      urlParams.sliders = urlParams.sliders.split(' ')
    }
    if (!urlParams.sliders.length) {
      delete urlParams.sliders
    }
    return urlParams
  }
  inject('parsedFromUrl', parsedFromUrl)
}
