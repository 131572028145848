import Vue from 'vue'

import { mapState } from 'vuex'

const UserMixin = {
  install (Vue, options) {
    Vue.mixin({
      computed: {
        ...mapState({
          user: state => state.auth.user,
          authenticated: state => state.auth.loggedIn
        })
      }
    })
  }
}

Vue.use(UserMixin)
