<template>
  <div>
    <div class="page_background tank_you_bg home_bg" />
    <section class="tank_you">
      <div class="container">
        <div class="tank_you_wrap">
          <div class="tank_you_top">
            <div class="ty_title">
              <h1 v-html="error.message ? error.message : $i18n.t('error.title_404')" />
            </div>
            <div class="ty_descr" v-text="$t('error.text_404')" />
          </div>
        </div>
        <Subscribe :class="`thank_you_subscribe`" />
      </div>
    </section>
  </div>
</template>

<script>
import Subscribe from '@/components/subscribe/Subscribe'

export default {
  components: {
    Subscribe
  },
  props: {
    error: {
      type: Object,
      default: () => {}
    }
  },
  head () {
    return {
      title: this.error.message ?? this.$i18n.t('error.title_404'),
      meta: [
        { name: 'robots', content: 'noindex, nofollow' }
      ]
    }
  }
}
</script>

<style type="text/css" scoped>
.tank_you .tank_you_top .ty_title {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 28px;
}
</style>
