const middleware = {}

middleware['authenticated'] = require('../middleware/authenticated.js')
middleware['authenticated'] = middleware['authenticated'].default || middleware['authenticated']

middleware['botDetection'] = require('../middleware/botDetection.js')
middleware['botDetection'] = middleware['botDetection'].default || middleware['botDetection']

middleware['clearValidationErrors'] = require('../middleware/clearValidationErrors.js')
middleware['clearValidationErrors'] = middleware['clearValidationErrors'].default || middleware['clearValidationErrors']

middleware['guest'] = require('../middleware/guest.js')
middleware['guest'] = middleware['guest'].default || middleware['guest']

middleware['maintenance'] = require('../middleware/maintenance.js')
middleware['maintenance'] = middleware['maintenance'].default || middleware['maintenance']

middleware['redirects'] = require('../middleware/redirects.js')
middleware['redirects'] = middleware['redirects'].default || middleware['redirects']

export default middleware
