import Vue from 'vue'

export default () => {
  Vue.filter('mediaUrl', (item, field, size, locale) => {
    let image = {}
    if (locale) {
      const translation = item.translations.find(trans => trans.locale === locale)
      image = translation ? translation[field] : {}
    } else {
      image = item[field]
    }
    if (!image) {
      return '/icons/logo.svg'
    }
    const prefix = size === 'path' ? '/file-manager/' : ''

    if (Object.prototype.hasOwnProperty.call(image, 'media')) {
      return image.media[size] ? (prefix + image.media[size]) : '/icons/logo.svg'
    }

    return image[size] ? (prefix + image[size]) : '/icons/logo.svg'
  })

  Vue.filter('transl', (item, field, locale = 'uk') => {
    const translate = item.translations.find(trans => trans.locale === locale)
    return item.translations && translate && translate[field] ? translate[field] : item[field]
  })
}
