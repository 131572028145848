const global = require('./../data/global.json')

export const state = () => ({
  settings: global.settings,
  shops: global.shops,
  storageVersion: global.storageVersion
})

export const getters = {
  settings: ({ settings }) => settings,
  shops: ({ shops }) => shops
}

export const mutations = {
  SET_SETTINGS (state, { settings, shops }) {
    state.settings = settings
    state.shops = shops
  },
  SET_SHOPS (state, data) {
    state.shops = data
  }
}

export const actions = {
  setShops: ({ commit }, item) => commit('SET_SHOPS', item),
  setSettings: ({ commit }, item) => commit('SET_SETTINGS', item)
}
