import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _73e72c7c = () => interopDefault(import('../pages/account/index.vue' /* webpackChunkName: "pages/account/index" */))
const _49a0f949 = () => interopDefault(import('../pages/blog/index.vue' /* webpackChunkName: "pages/blog/index" */))
const _76eef3ed = () => interopDefault(import('../pages/checkout/index.vue' /* webpackChunkName: "pages/checkout/index" */))
const _f59dfec2 = () => interopDefault(import('../pages/maintenance.vue' /* webpackChunkName: "pages/maintenance" */))
const _7f818f57 = () => interopDefault(import('../pages/promotions/index.vue' /* webpackChunkName: "pages/promotions/index" */))
const _0ff9a5de = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _26f2a778 = () => interopDefault(import('../pages/account/favorites/index.vue' /* webpackChunkName: "pages/account/favorites/index" */))
const _b8b4e524 = () => interopDefault(import('../pages/account/orders/index.vue' /* webpackChunkName: "pages/account/orders/index" */))
const _3ae1dde1 = () => interopDefault(import('../pages/checkout/success/index.vue' /* webpackChunkName: "pages/checkout/success/index" */))
const _1259d152 = () => interopDefault(import('../pages/blog/category/_slug.vue' /* webpackChunkName: "pages/blog/category/_slug" */))
const _47ea5801 = () => interopDefault(import('../pages/blog/_slug.vue' /* webpackChunkName: "pages/blog/_slug" */))
const _1a35c7f0 = () => interopDefault(import('../pages/-catalog.vue' /* webpackChunkName: "" */))
const _de607914 = () => interopDefault(import('../pages/product/_slug.vue' /* webpackChunkName: "pages/product/_slug" */))
const _92ab255c = () => interopDefault(import('../pages/promotions/_slug/index.vue' /* webpackChunkName: "pages/promotions/_slug/index" */))
const _e616e292 = () => interopDefault(import('../pages/-category.vue' /* webpackChunkName: "" */))
const _3175fc4c = () => interopDefault(import('../pages/promotions/_slug/_filter/index.vue' /* webpackChunkName: "pages/promotions/_slug/_filter/index" */))
const _0e430496 = () => interopDefault(import('../pages/_slug.vue' /* webpackChunkName: "pages/_slug" */))
const _845d9c8e = () => interopDefault(import('../pages/-.vue' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/account",
    component: _73e72c7c,
    name: "account___uk"
  }, {
    path: "/blog",
    component: _49a0f949,
    name: "blog___uk"
  }, {
    path: "/checkout",
    component: _76eef3ed,
    name: "checkout___uk"
  }, {
    path: "/maintenance",
    component: _f59dfec2,
    name: "maintenance___uk"
  }, {
    path: "/promotions",
    component: _7f818f57,
    name: "promotions___uk"
  }, {
    path: "/ru",
    component: _0ff9a5de,
    name: "index___ru"
  }, {
    path: "/account/favorites",
    component: _26f2a778,
    name: "account-favorites___uk"
  }, {
    path: "/account/orders",
    component: _b8b4e524,
    name: "account-orders___uk"
  }, {
    path: "/checkout/success",
    component: _3ae1dde1,
    name: "checkout-success___uk"
  }, {
    path: "/ru/account",
    component: _73e72c7c,
    name: "account___ru"
  }, {
    path: "/ru/blog",
    component: _49a0f949,
    name: "blog___ru"
  }, {
    path: "/ru/checkout",
    component: _76eef3ed,
    name: "checkout___ru"
  }, {
    path: "/ru/maintenance",
    component: _f59dfec2,
    name: "maintenance___ru"
  }, {
    path: "/ru/promotions",
    component: _7f818f57,
    name: "promotions___ru"
  }, {
    path: "/ru/account/favorites",
    component: _26f2a778,
    name: "account-favorites___ru"
  }, {
    path: "/ru/account/orders",
    component: _b8b4e524,
    name: "account-orders___ru"
  }, {
    path: "/ru/checkout/success",
    component: _3ae1dde1,
    name: "checkout-success___ru"
  }, {
    path: "/ru/blog/category/:slug?",
    component: _1259d152,
    name: "blog-category-slug___ru"
  }, {
    path: "/blog/category/:slug?",
    component: _1259d152,
    name: "blog-category-slug___uk"
  }, {
    path: "/ru/blog/:slug",
    component: _47ea5801,
    name: "blog-slug___ru"
  }, {
    path: "/ru/catalog/:filter(filter-[\\w|\\d|\\.|-]*)?",
    component: _1a35c7f0,
    name: "catalog___ru"
  }, {
    path: "/ru/discounts/:filter(filter-[\\w|\\d|\\.|-]*)?",
    component: _1a35c7f0,
    name: "discounts___ru"
  }, {
    path: "/ru/expected/:filter(filter-[\\w|\\d|\\.|-]*)?",
    component: _1a35c7f0,
    name: "expected___ru"
  }, {
    path: "/ru/novelties/:filter(filter-[\\w|\\d|\\.|-]*)?",
    component: _1a35c7f0,
    name: "novelties___ru"
  }, {
    path: "/ru/product/:slug?",
    component: _de607914,
    name: "product-slug___ru"
  }, {
    path: "/ru/promotions/:slug",
    component: _92ab255c,
    name: "promotions-slug___ru"
  }, {
    path: "/ru/search/:filter(filter-[\\w|\\d|\\.|-]*)?",
    component: _1a35c7f0,
    name: "search___ru"
  }, {
    path: "/ru/category/:slug/:filter(filter-[\\w|\\d|\\.|-]*)?",
    component: _e616e292,
    name: "category___ru"
  }, {
    path: "/ru/promotions/:slug/:filter",
    component: _3175fc4c,
    name: "promotions-slug-filter___ru"
  }, {
    path: "/blog/:slug",
    component: _47ea5801,
    name: "blog-slug___uk"
  }, {
    path: "/catalog/:filter(filter-[\\w|\\d|\\.|-]*)?",
    component: _1a35c7f0,
    name: "catalog___uk"
  }, {
    path: "/discounts/:filter(filter-[\\w|\\d|\\.|-]*)?",
    component: _1a35c7f0,
    name: "discounts___uk"
  }, {
    path: "/expected/:filter(filter-[\\w|\\d|\\.|-]*)?",
    component: _1a35c7f0,
    name: "expected___uk"
  }, {
    path: "/novelties/:filter(filter-[\\w|\\d|\\.|-]*)?",
    component: _1a35c7f0,
    name: "novelties___uk"
  }, {
    path: "/product/:slug?",
    component: _de607914,
    name: "product-slug___uk"
  }, {
    path: "/promotions/:slug",
    component: _92ab255c,
    name: "promotions-slug___uk"
  }, {
    path: "/ru/:slug",
    component: _0e430496,
    name: "slug___ru"
  }, {
    path: "/search/:filter(filter-[\\w|\\d|\\.|-]*)?",
    component: _1a35c7f0,
    name: "search___uk"
  }, {
    path: "/category/:slug/:filter(filter-[\\w|\\d|\\.|-]*)?",
    component: _e616e292,
    name: "category___uk"
  }, {
    path: "/promotions/:slug/:filter",
    component: _3175fc4c,
    name: "promotions-slug-filter___uk"
  }, {
    path: "/ru/*",
    component: _845d9c8e,
    name: "error___ru"
  }, {
    path: "/",
    component: _0ff9a5de,
    name: "index___uk"
  }, {
    path: "/:slug",
    component: _0e430496,
    name: "slug___uk"
  }, {
    path: "/*",
    component: _845d9c8e,
    name: "error___uk"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
