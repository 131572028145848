import Vue from 'vue'

export const state = () => ({
  sessionId: false,
  favorites: {},
  viewed: {},
  coupon: null,
  cart: {},
  order: {},
  invoice: {},
  upsells: [],
  totals: {},
  delivery: {
    delivery_free_cost: 2000,
    delivery_total: 150
  }
})

export const getters = {
  sessionId: ({ sessionId }) => sessionId,
  favorites: ({ favorites }) => favorites,
  total_favorites: ({ favorites }) => {
    return Object.values(favorites).length
  },
  viewed: ({ viewed }) => viewed,
  total_viewed: ({ viewed }) => {
    return Object.values(viewed).length
  },
  upsells: ({ upsells }) => upsells,
  cart: ({ cart }) => cart,
  order: ({ order }) => order,
  invoice: ({ invoice }) => invoice,
  totals: ({ totals }) => totals,
  total: ({ cart }) => {
    return Object.values(cart).reduce((total, { qty }) => qty + total, 0)
  },
  amount: ({ cart }) => {
    return Object.values(cart).reduce(
      (total, { qty, price, options }) =>
        options.sale_price ? total + qty * options.sale_price : total + qty * price,
      0
    )
  },
  shipping_fee: (state, getters) => {
    return getters.amount >= state.delivery.delivery_free_cost ? 0 : state.delivery.delivery_total
  },
  free_shipping_progress: (state, getters) => {
    return getters.amount >= state.delivery.delivery_free_cost ? 0 : (state.delivery.delivery_free_cost - getters.amount)
  },
  free_shipping_progress_percent: (state, getters) => {
    return 100 - getters.free_shipping_progress * 100 / state.delivery.delivery_free_cost
  },
  discount: (state, getters) => {
    // if (
    //   !state.coupon.value ||
    //   (state.coupon.min && getters.amount < state.coupon.min)
    // ) { return 0 }
    // if (!state.coupon.is_percent) { return state.coupon.value }
    // const discount = (getters.amount * state.coupon.value) / 100
    // const max = state.coupon.max
    // return !max || discount < max ? discount : max
    return 0
  },
  total_after_discount: (state, getters) => {
    return getters.amount - getters.discount
  },
  total_amount: (state, getters) => {
    const totalAmount = getters.total_after_discount
    return totalAmount > 0
      ? totalAmount + getters.shipping_fee
      : getters.shipping_fee
  },
  coupon: ({ coupon }) => coupon
}

export const mutations = {
  SET_SESSION: (state, item) => {
    state.sessionId = item
  },
  SET_FAVORITES: (state, { data }) => {
    Vue.set(state, 'favorites', data)
  },
  SET_VIEWED: (state, { data }) => {
    Vue.set(state, 'viewed', data)
  },
  SET_CART: (state, { data, totals, delivery, coupon }) => {
    Vue.set(state, 'cart', data)
    Vue.set(state, 'totals', totals)
    Vue.set(state, 'delivery', delivery)
    Vue.set(state, 'coupon', coupon)
  },
  SET_ORDER: (state, { data, invoice }) => {
    Vue.set(state, 'order', data)
    Vue.set(state, 'invoice', invoice)
  },
  SET_UPSELLS: (state, { data }) => {
    Vue.set(state, 'upsells', data)
  }
}

export const actions = {
  async fetchSession ({ state, commit }) {
    if (state.sessionId && state.sessionId !== 'undefined' && state.sessionId !== false) {
      commit('SET_SESSION', state.sessionId)
      await this.$axios.$get('/api/cart/', { headers: { 'Guest-Token': `${state.sessionId}` } }).then((res) => {
        commit('SET_CART', res)
      })
    } else {
      await this.$axios.$post('/api/session/').then((res) => {
        commit('SET_SESSION', res.token)
      })
    }
  },

  setSession: ({ commit }, item) => commit('SET_SESSION', item),

  setCart: ({ commit }, item) => commit('SET_CART', item),

  setFavorites: ({ commit }, item) => commit('SET_FAVORITES', item),

  setViewed: ({ commit }, item) => commit('SET_VIEWED', item),

  setUpsells: ({ commit }, item) => commit('SET_UPSELLS', item),

  setOrder: ({ commit }, item) => commit('SET_ORDER', item)
}
