import Vue from 'vue'
import Vuex from 'vuex'
import Meta from 'vue-meta'
import ClientOnly from 'vue-client-only'
import NoSsr from 'vue-no-ssr'
import { createRouter } from './router.js'
import NuxtChild from './components/nuxt-child.js'
import NuxtError from '../layouts/error.vue'
import Nuxt from './components/nuxt.js'
import App from './App.js'
import { setContext, getLocation, getRouteData, normalizeError } from './utils'
import { createStore } from './store.js'

/* Plugins */

import nuxt_plugin_plugin_15b35d5c from 'nuxt_plugin_plugin_15b35d5c' // Source: ./composition-api/plugin.mjs (mode: 'all')
import nuxt_plugin_nuxtvuexlocalstorage_d62a0d52 from 'nuxt_plugin_nuxtvuexlocalstorage_d62a0d52' // Source: ./nuxt-vuex-localstorage.js (mode: 'client')
import nuxt_plugin_image_7b1db20a from 'nuxt_plugin_image_7b1db20a' // Source: ./image.js (mode: 'all')
import nuxt_plugin_cookieuniversalnuxt_16cfc9a8 from 'nuxt_plugin_cookieuniversalnuxt_16cfc9a8' // Source: ./cookie-universal-nuxt.js (mode: 'all')
import nuxt_plugin_webfontloader_e63d2000 from 'nuxt_plugin_webfontloader_e63d2000' // Source: ./webfontloader.js (mode: 'client')
import nuxt_plugin_templatesplugin4a9990c9_dc6c7114 from 'nuxt_plugin_templatesplugin4a9990c9_dc6c7114' // Source: ./templates.plugin.4a9990c9.js (mode: 'client')
import nuxt_plugin_gtm_88251e54 from 'nuxt_plugin_gtm_88251e54' // Source: ./gtm.js (mode: 'all')
import nuxt_plugin_toast_b8cb12a2 from 'nuxt_plugin_toast_b8cb12a2' // Source: ./toast.js (mode: 'client')
import nuxt_plugin_pluginutils_6694f78a from 'nuxt_plugin_pluginutils_6694f78a' // Source: ./nuxt-i18n/plugin.utils.js (mode: 'all')
import nuxt_plugin_pluginrouting_2b65eec6 from 'nuxt_plugin_pluginrouting_2b65eec6' // Source: ./nuxt-i18n/plugin.routing.js (mode: 'all')
import nuxt_plugin_pluginmain_7088e269 from 'nuxt_plugin_pluginmain_7088e269' // Source: ./nuxt-i18n/plugin.main.js (mode: 'all')
import nuxt_plugin_axios_512bac00 from 'nuxt_plugin_axios_512bac00' // Source: ./axios.js (mode: 'all')
import nuxt_plugin_index_10adf992 from 'nuxt_plugin_index_10adf992' // Source: ../filters/index (mode: 'all')
import nuxt_plugin_i18n_6a80ea94 from 'nuxt_plugin_i18n_6a80ea94' // Source: ../plugins/i18n (mode: 'all')
import nuxt_plugin_UserMixin_40f89980 from 'nuxt_plugin_UserMixin_40f89980' // Source: ../mixins/UserMixin (mode: 'all')
import nuxt_plugin_axios_3566aa80 from 'nuxt_plugin_axios_3566aa80' // Source: ../plugins/axios (mode: 'all')
import nuxt_plugin_globalmethod_41231e95 from 'nuxt_plugin_globalmethod_41231e95' // Source: ../plugins/global-method (mode: 'all')
import nuxt_plugin_wfp_5e46744b from 'nuxt_plugin_wfp_5e46744b' // Source: ../plugins/wfp (mode: 'client')
import nuxt_plugin_vmask_3801a64e from 'nuxt_plugin_vmask_3801a64e' // Source: ../plugins/v-mask (mode: 'client')
import nuxt_plugin_jsonld_593ee954 from 'nuxt_plugin_jsonld_593ee954' // Source: ../plugins/jsonld (mode: 'all')
import nuxt_plugin_errors_6a68cb3e from 'nuxt_plugin_errors_6a68cb3e' // Source: ../plugins/errors (mode: 'all')
import nuxt_plugin_plugin_bc2c9188 from 'nuxt_plugin_plugin_bc2c9188' // Source: ./auth/plugin.js (mode: 'all')
import nuxt_plugin_auth_6a7e4e1e from 'nuxt_plugin_auth_6a7e4e1e' // Source: ../plugins/auth (mode: 'all')
import nuxt_plugin_meta_54b658e4 from 'nuxt_plugin_meta_54b658e4' // Source: ./composition-api/meta.mjs (mode: 'all')

// Component: <ClientOnly>
Vue.component(ClientOnly.name, ClientOnly)

// TODO: Remove in Nuxt 3: <NoSsr>
Vue.component(NoSsr.name, {
  ...NoSsr,
  render (h, ctx) {
    if (process.client && !NoSsr._warned) {
      NoSsr._warned = true

      console.warn('<no-ssr> has been deprecated and will be removed in Nuxt 3, please use <client-only> instead')
    }
    return NoSsr.render(h, ctx)
  }
})

// Component: <NuxtChild>
Vue.component(NuxtChild.name, NuxtChild)
Vue.component('NChild', NuxtChild)

// Component NuxtLink is imported in server.js or client.js

// Component: <Nuxt>
Vue.component(Nuxt.name, Nuxt)

Object.defineProperty(Vue.prototype, '$nuxt', {
  get() {
    const globalNuxt = this.$root.$options.$nuxt
    if (process.client && !globalNuxt && typeof window !== 'undefined') {
      return window.$nuxt
    }
    return globalNuxt
  },
  configurable: true
})

Vue.use(Meta, {"keyName":"head","attribute":"data-n-head","ssrAttribute":"data-n-head-ssr","tagIDKeyName":"hid"})

const defaultTransition = {"name":"page","mode":"out-in","appear":false,"appearClass":"appear","appearActiveClass":"appear-active","appearToClass":"appear-to"}

const originalRegisterModule = Vuex.Store.prototype.registerModule

function registerModule (path, rawModule, options = {}) {
  const preserveState = process.client && (
    Array.isArray(path)
      ? !!path.reduce((namespacedState, path) => namespacedState && namespacedState[path], this.state)
      : path in this.state
  )
  return originalRegisterModule.call(this, path, rawModule, { preserveState, ...options })
}

async function createApp(ssrContext, config = {}) {
  const router = await createRouter(ssrContext, config)

  const store = createStore(ssrContext)
  // Add this.$router into store actions/mutations
  store.$router = router

  // Fix SSR caveat https://github.com/nuxt/nuxt.js/issues/3757#issuecomment-414689141
  store.registerModule = registerModule

  // Create Root instance

  // here we inject the router and store to all child components,
  // making them available everywhere as `this.$router` and `this.$store`.
  const app = {
    head: {"titleTemplate":"%s","noscript":[{"innerHTML":"This website requires JavaScript."},{"hid":"gtm-noscript","pbody":true,"innerHTML":"\u003Ciframe src=\"https:\u002F\u002Fwww.googletagmanager.com\u002Fns.html?id=GTM-M6RHQBB&\" height=\"0\" width=\"0\" style=\"display:none;visibility:hidden\" title=\"gtm\"\u003E\u003C\u002Fiframe\u003E"}],"meta":[{"name":"author","skip":true},{"charset":"utf-8"},{"name":"theme-color","content":"#000000"},{"name":"viewport","content":"width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no"}],"link":[{"rel":"manifest","href":"\u002Fmanifest.json"},{"rel":"icon","type":"image\u002Fx-icon","href":"\u002Ffavicon.ico"},{"rel":"apple-touch-icon","type":"image\u002Fx-icon","href":"\u002Ffavicon.ico"}],"script":[{"type":"application\u002Fld+json","json":{"@context":"http:\u002F\u002Fschema.org","@type":"Organization","name":"Decibel","url":"https:\u002F\u002Fdecibel.com.ua","logo":"https:\u002F\u002Fdecibel.com.ua\u002Ficons\u002Flogo.svg"}},{"hid":"gtm-script","innerHTML":"if(!window._gtm_init){window._gtm_init=1;(function(w,n,d,m,e,p){w[d]=(w[d]==1||n[d]=='yes'||n[d]==1||n[m]==1||(w[e]&&w[e][p]&&w[e][p]()))?1:0})(window,navigator,'doNotTrack','msDoNotTrack','external','msTrackingProtectionEnabled');(function(w,d,s,l,x,y){w[x]={};w._gtm_inject=function(i){if(w.doNotTrack||w[x][i])return;w[x][i]=1;w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s);j.async=true;j.src='https:\u002F\u002Fwww.googletagmanager.com\u002Fgtm.js?id='+i;f.parentNode.insertBefore(j,f);};w[y]('GTM-M6RHQBB')})(window,document,'script','dataLayer','_gtm_ids','_gtm_inject')}"}],"style":[],"__dangerouslyDisableSanitizersByTagID":{"gtm-script":["innerHTML"],"gtm-noscript":["innerHTML"]}},

    store,
    router,
    nuxt: {
      defaultTransition,
      transitions: [defaultTransition],
      setTransitions (transitions) {
        if (!Array.isArray(transitions)) {
          transitions = [transitions]
        }
        transitions = transitions.map((transition) => {
          if (!transition) {
            transition = defaultTransition
          } else if (typeof transition === 'string') {
            transition = Object.assign({}, defaultTransition, { name: transition })
          } else {
            transition = Object.assign({}, defaultTransition, transition)
          }
          return transition
        })
        this.$options.nuxt.transitions = transitions
        return transitions
      },

      err: null,
      dateErr: null,
      error (err) {
        err = err || null
        app.context._errored = Boolean(err)
        err = err ? normalizeError(err) : null
        let nuxt = app.nuxt // to work with @vue/composition-api, see https://github.com/nuxt/nuxt.js/issues/6517#issuecomment-573280207
        if (this) {
          nuxt = this.nuxt || this.$options.nuxt
        }
        nuxt.dateErr = Date.now()
        nuxt.err = err
        // Used in src/server.js
        if (ssrContext) {
          ssrContext.nuxt.error = err
        }
        return err
      }
    },
    ...App
  }

  // Make app available into store via this.app
  store.app = app

  const next = ssrContext ? ssrContext.next : location => app.router.push(location)
  // Resolve route
  let route
  if (ssrContext) {
    route = router.resolve(ssrContext.url).route
  } else {
    const path = getLocation(router.options.base, router.options.mode)
    route = router.resolve(path).route
  }

  // Set context to app.context
  await setContext(app, {
    store,
    route,
    next,
    error: app.nuxt.error.bind(app),
    payload: ssrContext ? ssrContext.payload : undefined,
    req: ssrContext ? ssrContext.req : undefined,
    res: ssrContext ? ssrContext.res : undefined,
    beforeRenderFns: ssrContext ? ssrContext.beforeRenderFns : undefined,
    ssrContext
  })

  function inject(key, value) {
    if (!key) {
      throw new Error('inject(key, value) has no key provided')
    }
    if (value === undefined) {
      throw new Error(`inject('${key}', value) has no value provided`)
    }

    key = '$' + key
    // Add into app
    app[key] = value
    // Add into context
    if (!app.context[key]) {
      app.context[key] = value
    }

    // Add into store
    store[key] = app[key]

    // Check if plugin not already installed
    const installKey = '__nuxt_' + key + '_installed__'
    if (Vue[installKey]) {
      return
    }
    Vue[installKey] = true
    // Call Vue.use() to install the plugin into vm
    Vue.use(() => {
      if (!Object.prototype.hasOwnProperty.call(Vue.prototype, key)) {
        Object.defineProperty(Vue.prototype, key, {
          get () {
            return this.$root.$options[key]
          }
        })
      }
    })
  }

  // Inject runtime config as $config
  inject('config', config)

  if (process.client) {
    // Replace store state before plugins execution
    if (window.__NUXT__ && window.__NUXT__.state) {
      store.replaceState(window.__NUXT__.state)
    }
  }

  // Add enablePreview(previewData = {}) in context for plugins
  if (process.static && process.client) {
    app.context.enablePreview = function (previewData = {}) {
      app.previewData = Object.assign({}, previewData)
      inject('preview', previewData)
    }
  }
  // Plugin execution

  if (typeof nuxt_plugin_plugin_15b35d5c === 'function') {
    await nuxt_plugin_plugin_15b35d5c(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_nuxtvuexlocalstorage_d62a0d52 === 'function') {
    await nuxt_plugin_nuxtvuexlocalstorage_d62a0d52(app.context, inject)
  }

  if (typeof nuxt_plugin_image_7b1db20a === 'function') {
    await nuxt_plugin_image_7b1db20a(app.context, inject)
  }

  if (typeof nuxt_plugin_cookieuniversalnuxt_16cfc9a8 === 'function') {
    await nuxt_plugin_cookieuniversalnuxt_16cfc9a8(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_webfontloader_e63d2000 === 'function') {
    await nuxt_plugin_webfontloader_e63d2000(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_templatesplugin4a9990c9_dc6c7114 === 'function') {
    await nuxt_plugin_templatesplugin4a9990c9_dc6c7114(app.context, inject)
  }

  if (typeof nuxt_plugin_gtm_88251e54 === 'function') {
    await nuxt_plugin_gtm_88251e54(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_toast_b8cb12a2 === 'function') {
    await nuxt_plugin_toast_b8cb12a2(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginutils_6694f78a === 'function') {
    await nuxt_plugin_pluginutils_6694f78a(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginrouting_2b65eec6 === 'function') {
    await nuxt_plugin_pluginrouting_2b65eec6(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginmain_7088e269 === 'function') {
    await nuxt_plugin_pluginmain_7088e269(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_512bac00 === 'function') {
    await nuxt_plugin_axios_512bac00(app.context, inject)
  }

  if (typeof nuxt_plugin_index_10adf992 === 'function') {
    await nuxt_plugin_index_10adf992(app.context, inject)
  }

  if (typeof nuxt_plugin_i18n_6a80ea94 === 'function') {
    await nuxt_plugin_i18n_6a80ea94(app.context, inject)
  }

  if (typeof nuxt_plugin_UserMixin_40f89980 === 'function') {
    await nuxt_plugin_UserMixin_40f89980(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_3566aa80 === 'function') {
    await nuxt_plugin_axios_3566aa80(app.context, inject)
  }

  if (typeof nuxt_plugin_globalmethod_41231e95 === 'function') {
    await nuxt_plugin_globalmethod_41231e95(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_wfp_5e46744b === 'function') {
    await nuxt_plugin_wfp_5e46744b(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vmask_3801a64e === 'function') {
    await nuxt_plugin_vmask_3801a64e(app.context, inject)
  }

  if (typeof nuxt_plugin_jsonld_593ee954 === 'function') {
    await nuxt_plugin_jsonld_593ee954(app.context, inject)
  }

  if (typeof nuxt_plugin_errors_6a68cb3e === 'function') {
    await nuxt_plugin_errors_6a68cb3e(app.context, inject)
  }

  if (typeof nuxt_plugin_plugin_bc2c9188 === 'function') {
    await nuxt_plugin_plugin_bc2c9188(app.context, inject)
  }

  if (typeof nuxt_plugin_auth_6a7e4e1e === 'function') {
    await nuxt_plugin_auth_6a7e4e1e(app.context, inject)
  }

  if (typeof nuxt_plugin_meta_54b658e4 === 'function') {
    await nuxt_plugin_meta_54b658e4(app.context, inject)
  }

  // Lock enablePreview in context
  if (process.static && process.client) {
    app.context.enablePreview = function () {
      console.warn('You cannot call enablePreview() outside a plugin.')
    }
  }

  // Wait for async component to be resolved first
  await new Promise((resolve, reject) => {
    // Ignore 404s rather than blindly replacing URL in browser
    if (process.client) {
      const { route } = router.resolve(app.context.route.fullPath)
      if (!route.matched.length) {
        return resolve()
      }
    }
    router.replace(app.context.route.fullPath, resolve, (err) => {
      // https://github.com/vuejs/vue-router/blob/v3.4.3/src/util/errors.js
      if (!err._isRouter) return reject(err)
      if (err.type !== 2 /* NavigationFailureType.redirected */) return resolve()

      // navigated to a different route in router guard
      const unregister = router.afterEach(async (to, from) => {
        if (process.server && ssrContext && ssrContext.url) {
          ssrContext.url = to.fullPath
        }
        app.context.route = await getRouteData(to)
        app.context.params = to.params || {}
        app.context.query = to.query || {}
        unregister()
        resolve()
      })
    })
  })

  return {
    store,
    app,
    router
  }
}

export { createApp, NuxtError }
