export default function ({ req, redirect }) {
  const userAgent = req.headers['user-agent']

  // Ось список ознак бота, які можна використовувати для визначення
  const botIndicators = ['bot', 'crawler', 'spider', 'googlebot', 'bingbot', 'yandexbot']

  // Перевіряємо, чи агент користувача містить ознаки бота
  const isBot = botIndicators.some(indicator => userAgent.toLowerCase().includes(indicator))

  // Якщо це бот, можна вирізати скрипти або виконати інші дії
  if (isBot) {
    // Додайте код для обробки бота, наприклад, видалення скриптів
    // Наприклад, можливо вам знадобиться створити змінну для вказівки, що це бот
    // і використовувати її в шаблонах для вирізання скриптів або іншої обробки

    // Приклад редиректу, якщо бот
    // return redirect('/error') // Редирект на сторінку помилки
    console.log('isBot')
    console.log(userAgent)
    // const htmlElement = document.documentElement
    // const scriptElements = htmlElement.querySelectorAll('script')
    // scriptElements.forEach((scriptElement) => {
    //   if (scriptElement.hasAttribute('src')) {
    //     scriptElement.remove()
    //   }
    // })
  } else {
    console.log('NOT isBot')
    console.log(userAgent)
  }
}
