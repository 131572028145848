export const getters = {
  isAuthenticated: ({ auth }) => auth.loggedIn,
  isGuest: ({ auth }) => !auth.loggedIn,
  loggedInUser: ({ auth }) => auth.user
}

export const actions = {
  // 123
}

export const strict = false
