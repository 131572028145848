export const state = () => ({
  enabled: false
})

export const getters = {
  enabled (state) {
    return state.enabled
  },
  disabled (state) {
    return !state.enabled
  }
}

export const mutations = {
  SET_VALUE (state, value) {
    state.enabled = value
  }
}

export const actions = {
  enable ({ commit }) {
    commit('SET_VALUE', true)
  },
  disable ({ commit }) {
    commit('SET_VALUE', false)
  }
}
