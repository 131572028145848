const redirects = require('../301.json')

module.exports = function (req, res, next) {
  const url = req.url.split('?')[0]
  let newParams = ''
  let urlParams = ''
  if (req.url.includes('?')) {
    urlParams = newParams = req.url.split('?')[1]
    if (newParams.split('&').includes('page=0')) {
      newParams = newParams.replace('page=0', '')
    }
    if (newParams.split('&').includes('page=1')) {
      newParams = newParams.replace('page=1', '')
    }
  }
  const redirect = redirects.find(r => r.from === url)

  if (redirect) {
    const newLocation = redirect.to + newParams
    res.writeHead(301, {
      Location: newLocation
    })
    res.end()
  } else {
    let newUrl = url
    if (newUrl.includes(['.svg']) || newUrl.includes(['.png']) || newUrl.includes(['.jpg']) || newUrl.includes(['.webp']) || newUrl.includes(['.jpeg'])) {
      return next()
    }
    if (url !== url.toLowerCase()) {
      newUrl = url.toLowerCase()
    }
    if (newUrl.charAt(newUrl.length - 1) !== '/') {
      newUrl = newUrl + '/'
    }
    if (newUrl.includes('/home.php')) {
      newUrl = newUrl.replace('/home.php', '/')
    }
    if (newUrl.includes('/home.html')) {
      newUrl = newUrl.replace('/home.html', '/')
    }
    if (newUrl.includes('/home/')) {
      newUrl = newUrl.replace('/home/', '/')
    }
    if (newUrl.includes('/filter-/')) {
      newUrl = newUrl.replace('/filter-/', '/')
    }
    if (newUrl.includes('/uk/')) {
      newUrl = newUrl.replace('/uk/', '/')
    }
    if (newUrl.includes('//')) {
      newUrl = newUrl.replace(/\/\/+/g, '/')
    }
    if (newUrl !== url || newParams !== urlParams) {
      res.writeHead(301, {
        Location: newUrl + (newParams ? `?${newParams}` : '')
      })
      res.end()
    } else {
      return next()
    }
  }
}
