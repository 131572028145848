export const state = () => ({
  errors: {},
  successes: {}
})

export const getters = {
  errors (state) {
    return state.errors
  },
  successes (state) {
    return state.successes
  }
}

export const mutations = {
  SET_VALIDATION_ERRORS (state, errors) {
    state.errors = errors
  },
  SET_VALIDATION_SUCCESS (state, successes) {
    state.successes = successes
  }
}

export const actions = {
  setErrors ({ commit }, errors) {
    commit('SET_VALIDATION_ERRORS', errors)
  },
  setSuccess ({ commit }, successes) {
    commit('SET_VALIDATION_SUCCESS', successes)
  },
  clearErrors ({ commit }) {
    commit('SET_VALIDATION_ERRORS', {})
    commit('SET_VALIDATION_SUCCESS', {})
  }
}
