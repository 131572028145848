import https from 'https'

export default ({ $axios, app, store, error, params, redirect }) => {
  $axios.defaults.httpsAgent = new https.Agent({ rejectUnauthorized: false })

  $axios.onError((e) => {
    if (e.response.status === 422) {
      store.dispatch('validation/setErrors', e.response.data.errors)
    }

    if (e.response.status === 401) {
      error({ statusCode: 401 })
    }

    if (e.response.status === 404) {
      error({ statusCode: 404 })
    }

    if (e.response.status === 301) {
      redirect('301', e.response.data.message)
    }

    return Promise.reject(e)
  })

  $axios.onRequest(() => {
    store.dispatch('validation/clearErrors')
  })

  $axios.setHeader('Content-Language', app.i18n.locale)

  $axios.setHeader('Guest-Token', app.store.getters['cart/sessionId'])
}
